import React from "react";
import "./other.scss";



const OtherPage = () => {
    return ( 
        <div className="other-page">   

            <div id='other-description'>
            Allow us to bring visions of your events to life,
            We promise to give you the best.
            Kindly contact us for any inquiries. 
            </div>
            
          </div>
     );
}
 
export default OtherPage;
