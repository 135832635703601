import React from 'react';
import './menu-icon.scss';

const MenuIcon = () => {
    return ( 
        <div className='menu-icon-container'>
            <div className='menu-icon-bars'>

            </div>

        </div>
     );
}
 
export default MenuIcon;