import styled from 'styled-components';

export const ImageSliderContainer = styled.div`  
    width: 100vw;
    height: 70vh;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;    
    position: relative;
    overflow: hidden;
    
    
    

`;

// export const ImageWrapper = styled.div` 
//     min-width: 100vw;
//     height: 70vh;
//     transition: .2.5s ease-in-out ;
//     overflow: hidden;       
        
//     background-size: cover;
//     object-fit: cover;
//     background-repeat: no-repeat;
//     background-position: center;   
//     background-image: linear-gradient(
//             rgba(21, 28, 58, 0.38),
//             rgba(21, 28, 58, 0.68)
//             ),
//             url(${props=> props.imageUrl});    
    
//     overflow: hidden;
    
    
// `;
