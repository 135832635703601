import { TOGGLE_NAVIGATION_OPEN,
         TOGGLE_OPTION_DROPDOWN_OPEN, 
         TOGGLE_SUCCESS_MESSAGE_ON } 
        from './modal-actions.types';

export const toggleNavigationOpen = ({    
    type: TOGGLE_NAVIGATION_OPEN       

})

export const toggleOptionDropdownOpen = ({    
    type: TOGGLE_OPTION_DROPDOWN_OPEN      

})

export const toggleSuccessMessageOn = ({    
    type: TOGGLE_SUCCESS_MESSAGE_ON      

})